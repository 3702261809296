import isEmpty from 'lodash/isEmpty';
/**
 * API general class
 * This class constructor is used so we don't have to reuse the same code over again when it comes to fetching and headers
 * This is used calling the new API class such as const api = new Api();
 * required parameter to be passed in are the userSecurityTokenKey and username in that order with dataSource being lasted
 * The dataSource is only used on some calls and may not need to be passed in
 * const api = new Api(); and then api.get(), api.post(), api.put(), api.delete() with the required parameters passed in
 */

/**
  * Timeout function used to set a timeout for the API response
  * If the api takes longer than 10 seconds then we need to reject the promise being made
  * @param {Integer} time (miliseconds) time in which the timeout should trigger
  * @param {Promise} promise the promise function passed as the parameter
  */
const apiTimeout = (time, promise) => 
  new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('There is an error in server connection. Error Code: 280'))
    }, time);
    promise.then(resolve, reject);
  });

const checkResponseForUserNotFoundError = (apiResponse) => {
  if (!isEmpty(apiResponse) && !isEmpty(apiResponse.error)) {
    if (apiResponse.error.message === 'User Not Found or Inactive User') {
      window.location.assign('/login');
    }
  }};

class Api {
  constructor(userSecurityTokenKey, username, dataSource, roleId) {
    this.userSecurityTokenKey = userSecurityTokenKey;
    this.username = username;
    this.dataSource = dataSource;
    this.role = roleId;
  }

  async get(url) {
    try {
      const getRequest = await apiTimeout(600000, fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          authorization: this.userSecurityTokenKey,
          username: this.username,
          role: this.role,
          DataSource: this.dataSource,
          'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
          Pragma: 'no-cache',
          Expires: '-1',
          'Connection': 'keep-alive'
        }
      }));
      const getResponse = await getRequest.json();

      checkResponseForUserNotFoundError(getResponse);

      return getResponse;

    } catch(thrownError) {
      /**
       * Creating a custom error message from the timeout error
       * If there is a timeout error then we will display the custom message
       * If not then we will throw the original message coming back from the API
       */
      const error = thrownError.message === 'There is an error in server connection. Error Code: 280' ? {
        errorId: 'api-server-error',
        message: 'There is an error in server connection. Error Code: 280',
        code: 280
      } : thrownError;

      // Throwing the object like this to get around the eslint rule of 'Expected an object to be thrown'
      throw Object.assign(
        new Error(error.message),
        { errorId: error.errorId, message: error.message, code: error.code }
      );
    }
  }

  async post(url, dataToPost) {
    try {
      const postRequest = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: this.userSecurityTokenKey,
          username: this.username,
          role: this.role,
          DataSource: this.dataSource,
          'content-type': 'application/json',
          'Connection': 'keep-alive'
        },
        body: dataToPost,
      });
      const postResponse = await postRequest.json();

      checkResponseForUserNotFoundError(postResponse);
    
      return postResponse;

    } catch(error) {
      throw error;
    }
  }

  async put(url, dataToUpdate) {
    try {
      const putRequest = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          Authorization: this.userSecurityTokenKey,
          username: this.username,
          role: this.role,
          DataSource: this.dataSource,
          'content-type': 'application/json',
          'Connection': 'keep-alive'
        },
    
        body: dataToUpdate
      });
      const putResponse = await putRequest.json();

      checkResponseForUserNotFoundError(putResponse);
  
      return putResponse;

    } catch(error) {
      throw error;
    }
  }

  async delete(url, dataToDelete) {
    try {
      const deleteRequest = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: this.userSecurityTokenKey,
          username: this.username,
          role: this.role,
          DataSource: this.dataSource,
          'content-type': 'application/json',
          'Connection': 'keep-alive'
        },
        body: dataToDelete,
      });
      const deleteResponse = await deleteRequest.json();

      checkResponseForUserNotFoundError(deleteResponse);
  
      return deleteResponse;

    } catch (error) {
      throw error;
    }
  }
}

export default Api;