export const LEFT_STRIP_ANIM_STATES = {
  EXPANDED: 0,
  FADE_CONTENT: 1,
  EXPAND_COLLAPSE: 2,
  COLLAPSED: 3,
};

export const NAV_MENU_ITEMS = [
  [
    {
      id: 0,
      label: 'Home',
      path: '/',
    },
    {
      id: 1,
      label: 'Example Grid Page',
      path: '/example-grid',
    },
    {
      id: 2,
      label: 'Blank Page',
      path: '/example-blank',
    },
  ],
  [
    {
      id: 1,
      label: 'MVP Features',
      path: '/',
      labelDecoration: 'dev',
    },
    {
      id: 2,
      label: 'Future Features',
      path: '/',
      labelDecoration: 'dev',
    },
    {
      id: 2,
      label: 'Component Guide',
      path: '/',
      labelDecoration: 'dev',
    },
  ],
];

export const DEMO_USER = {
  sub: 'test1.dffkj@org1.com',
  aud: 'https://sdfkjldfskjl.chhc.io',
  jti: 'RgGcjg6ZJonYXnR24wjoIa',
  iss: 'https://sso-test.changehealthcare.com',
  iat: 1534264941,
  exp: 1534266741,
  phone_numbers: {
    number: '',
    type: 'work',
  },
  roles: 'User',
  photo: 'https://media.forgecdn.net/avatars/124/768/636424778749237239.jpeg',
  company: {
    name: 'Florida Blue',
    id: 'be5bf4cb-ddd6-4532-8e4f-a3f2df847650',
  },
  given_name: 'Test1',
  uuid: '716a2271-12d9-4007-8054-6bcabb4c7052',
  family_name: 'User1',
  email: 'test1.dffkj@org1.com',
  apps: [
    {
      name: 'Boilerplate',
      id: '7c7-4ff9-b4a8-8e0a00354d8a',
      uri: 'https://sdfkjldfskjl.chhc.io',
    },
    {
      name: 'Other App',
      id: '7c7-4ff9-b4a8-8e0a0',
      uri: 'https://otherapp9394.chhc.io',
    },
    {
      name: 'Third App',
      id: '7c7-4ff9-b4a8-8e0a0',
      uri: 'https://thirdapp34984.chhc.io',
    },
  ],
};

export const SUPPORT_MENU_ITEMS = [
  {
    id: 0,
    label: 'MenuItem1',
    path: '/',
  },
  {
    id: 1,
    label: 'MenuItem2',
    path: '/',
  },
  {
    id: 2,
    label: 'MenuItem3',
    path: '/',
  },
];

export const AVATAR_MENU_ITEMS = [
  [
    {
      id: 0,
      label: 'My Profile',
      path: '/',
    },
    {
      id: 1,
      label: 'My Views',
      path: '/',
    },
  ],
  [
    {
      id: 2,
      label: 'Log Out',
      path: '/login',
    },
  ],
  [
    {
      id: 3,
      label: 'Altegra Health Plan',
      path: '/',
    },
    {
      id: 4,
      label: 'Lorem Ipsum Plan',
      path: '/',
    },
  ],
];

export const SINGLE_SELECT_GROUP_ITEMS = {
  items: [
    {
      text: 'Title One',
      items: [
        {
          text: 'Sub-Title One',
          items: [
            'item one',
            {
              text: 'item two',
              count: 4,
              tooltipText: 'Example Tooltip',
              tooltipWidth: 200,
            },
          ],
        },
      ],
    },
  ],
  onOpen: () => false,
  onSelect: () => false,
  domID: 'single-select-group',
};

export const MULTI_SELECT_GROUP_ITEMS = {
  items: [
    {
      id: 'one',
      text: 'one',
      items: [
        {
          id: 'two',
          text: 'two',
          count: 2,
        },
        {
          id: 'three',
          text: 'three',
          items: [
            {
              id: 'four',
              text: 'four',
            },
            {
              id: 'five',
              text: 'five',
            },
          ],
        },
      ],
    },
  ],
  domID: 'multi-select-group',
};

export const DEFAULT_LOGO_REDIRECT_PATH = '/';

export const ON_PRODUCT_MENU_SELECT =
  'app/containers/PageWrapper/ON_PRODUCT_MENU_SELECT';
export const ON_AVATAR_MENU_SELECT =
  'app/containers/PageWrapper/ON_AVATAR_MENU_SELECT';
export const ON_NAV_MENU_SELECT =
  'app/containers/PageWrapper/ON_NAV_MENU_SELECT';
export const ON_MULTI_GROUP_OPEN =
  'app/containers/PageWrapper/ON_MULTI_GROUP_OPEN';
export const ON_MULTI_GROUP_CHECK =
  'app/containers/PageWrapper/ON_MULTI_GROUP_CHECK';
export const ON_SINGLE_GROUP_SELECT =
  'app/view/PageWrapper/ON_SINGLE_GROUP_SELECT';
export const ON_SINGLE_GROUP_OPEN = 'app/view/PageWrapper/ON_SINGLE_GROUP_OPEN';

export const ON_LEFT_STRIP_ADVANCE_ANIMATION =
  'app/containers/PageWrapper/ON_LEFT_STRIP_ADVANCE_ANIMATION';
export const ON_LEFT_STRIP_RETREAT_ANIMATION =
  'app/containers/PageWrapper/ON_LEFT_STRIP_RETREAT_ANIMATION';
export const ON_LEFT_STRIP_EXPAND_COLLAPSE =
  'app/containers/PageWrapper/ON_LEFT_STRIP_EXPAND_COLLAPSE';

export const USER_SET = 'app/containers/PageWrapper/USER_SET';

export const TOGGLE_AUTH_MODAL_OPEN =
  'app/containers/PageWrapper/TOGGLE_AUTH_MODAL_OPEN';

export const GET_NOTIFICATIONS_SUCCEEDED =
  'app/containers/PageWrapper/GET_NOTIFICATIONS_SUCCEEDED';
export const GET_NOTIFICATIONS_FAILED =
  'app/containers/PageWrapper/GET_NOTIFICATIONS_FAILED';
export const MARK_NOTIFICATION_READ_SUCCEEDED =
  'app/containers/PageWrapper/MARK_NOTIFICATION_READ_SUCCEEDED';
export const MARK_NOTIFICATION_READ_FAILED =
  'app/containers/PageWrapper/MARK_NOTIFICATION_READ_FAILED';
export const MARK_ALL_NOTIFICATIONS_READ_SUCCEEDED =
  'app/containers/PageWrapper/MARK_ALL_NOTIFICATIONS_READ_SUCCEEDED';
export const MARK_ALL_NOTIFICATIONS_READ_FAILED =
  'app/containers/PageWrapper/MARK_ALL_NOTIFICATIONS_READ_FAILED';

export const userPermissionConstants = {
  userRoleSuperuser: 'Superuser',
  userRoleEdit: 'Full Client SS GR | Edit',
  // following access allow user to enable commit button in rule flow
  CAN_PERFORM_GROUPRULES_RULETYPE_KEY_COMMIT_RULES: 'CAN_PERFORM_GROUPRULES_RULETYPE_KEY_COMMIT_RULES',
  // following access allow user to enable rule type in test association tab in group flow
  CAN_PERFORM_GROUPRULES_RULETYPE_KEY_EDIT_TEST_DETAILS: 'CAN_PERFORM_GROUPRULES_RULETYPE_KEY_EDIT_TEST_DETAILS',
  CAN_ACCESS_GROUPRULES_RULETYPE_KEY: 'CAN_ACCESS_GROUPRULES_RULETYPE_KEY',
  userRoleAllowedToRevert: ['00000001','00000002'], // superuser, account manager

  // error messages
  revertUserRoleNotAllowedAccessError: 'Only super users and account managers are authorized to revert a rule.'
};