import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginForm, Masthead, NotificationBanner, LoadingIndicator, Card, Button, Accordion, CollapsePanel, CollapsePanelTitle, CollapsePanelDetails, Warning } from 'ui-core/dist';
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom'
import { push } from 'connected-react-router';
import { Redirect } from 'react-router';
import useLocalStorage from '../../hooks/useLocalStorage';
import axios from '../../axios';
import LoggedOutFooter from '../../components/LoggedOutFooter';
import { setLogonDetails } from './modules/actions';
import { setSelected as navSelected } from '../SideNav/modules/actions';
import { useContextsList, useUpdateContext } from '../../api/useContexts';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { setContext, setPermissions } from '../ChooseContext/modules/actions'
import { setSupportItems } from '../PageWrapper/modules/actions';
import "./login.css";
import envConfig from '../../config/env-config';
import { OICoreConfig } from "../../config/oicore-config";
import * as axiosInstanceObject from 'axios';
import { useAuth } from "react-oidc-context";
import jwtDecode from "jwt-decode";
// import {linkCheck} from "link-check";
import moment from 'moment';
import { updateServerConfig } from '../../redux/app/serverConfigState/actions';


const Internalogin = () => {
  const prodLoginPage = false;
  const { APP_ENV = 'development' } = window;
  const API_CLIENT_ID = envConfig[APP_ENV].API_CLIENT_ID;
  const { REACT_APP_API_CLIENT_ID } = process.env;
  const Clientid = REACT_APP_API_CLIENT_ID ? REACT_APP_API_CLIENT_ID : API_CLIENT_ID;

  const REDIRECT_URI = envConfig[APP_ENV].REDIRECT_URI;
  const { REACT_APP_REDIRECT_URI } = process.env;
  const redirectURI = REACT_APP_REDIRECT_URI ? REACT_APP_REDIRECT_URI : REDIRECT_URI;
  const { REACT_APP_SENTINEL_URI } = process.env;
  const SENTINEL_URI = envConfig[APP_ENV].SENTINEL_URI;
  const SENTINEL_URI_temp = REACT_APP_SENTINEL_URI ? REACT_APP_SENTINEL_URI : SENTINEL_URI;



  const { signinRedirect } = useAuth();
  const auth = useAuth();



  const dispatch = useDispatch()
  const history = useHistory()
  const { clear } = useNotificationCenter()
  const { notify } = useNotificationBannerHook()
  const { userId, passwordExpired, token: sessionToken, } = useSelector(({ session }) => session)
  const { accessEntryId, dataSourceDesc, roleName } = useSelector(({ context }) => context?.context || {})
  const { isLoading: isLoadingContexts, isError: isErrorContextList, error: errorContextList, postData: getContexts, data: contextList } = useContextsList()
  const { isLoading: isLoadingUpdateContext, isError: isErrorUpdateContext, error: errorUpdateContext, updateContext, data: updateContextResponse } = useUpdateContext()

  const [count, setcount] = useState(0)
  const [state, setState] = useState({ key: 1, type: 'negative' })
  const [token, setToken] = useLocalStorage('user:token')
  const [isLoading, setIsLoading] = useState(false)
  const [showlogouttoast, setshowlogouttoast] = useState(false)
  const [signInTarget, setsignInTarget] = useState(false);
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [isInternalUser, setIsInternalUser] = useState(false);
  const [isUSerEnforcedforSentinelLogin, setIsUSerEnforcedforSentinelLogin] = useState(false);
  const [is_Identity_URL_Up, setIs_Identity_URL_Up] = useState(true);
  //
  const isMFALoggedInEnabled_config = envConfig[APP_ENV].isMFALoggedInEnabled;

  const [isMFALoggedInEnabled, setIsMFALoggedInEnabled] = useState(true);
  const [maintenanceMessage, setMaintenanceMessage] = useState('');
  const [isShowBanner, setIsShowBanner] = useState(false);
  
  // useEffect(() => {
  //   if (!prodLoginPage) {
  //     setIsLoading(true);
  //     axios.get('/auth/getMFAFeature', {
  //     }).then(res => {
  //       setIsMFALoggedInEnabled(true);
  //       setIs_Identity_URL_Up(res.data.is_Identity_URL_Up);
  //       setIsLoading(false);
  //     }).catch(err => {
  //       setIsMFALoggedInEnabled(true);
  //       setIsLoading(false);
  //     });
  //   }

  // }, []);

  //

  useEffect(() => {
    if (history?.location && history?.location?.state) {
      if (history?.location?.state === "timeout") setshowlogouttoast(true)
      history.replace({ ...history?.location, state: undefined });
    }
  }, [history?.location])

  useEffect(() => {
    if (showlogouttoast) {
      toast.warning(`You have been logged out due to session timeout.`, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      clear()
    }
  }, [showlogouttoast])

  // useEffect(() => {

  //   axiosInstanceObject.get(SENTINEL_URI).
  //   then(function(resp){

  //   }).catch(function(err){
  //     notify("We are not able to authenticate you at this time. We apologize for this inconvenience. Please try again later.", 'negative')
  //   });


  // }, [])

  useEffect(() => {
    checkHostName();
    if (isMFALoggedInEnabled) {
      const UrlText = <div>Log into <a target = '_blank' href = "https://community.changehealthcare.com/viewdocument/how-to-create-a-one-healthcare-id-f?CommunityKey=49f46c14-525c-4ea6-8cfb-6e3507079b8f">Community</a> and follow instructions on how to register with OHID for Multi-Factor Authentication</div>
      showNotificationforMFA(UrlText)
    }
  }, [isMFALoggedInEnabled])

  useEffect(() => {
    if (isErrorContextList && errorContextList) {
      if (errorContextList && Object.keys(errorContextList).length > 0
        && Object.keys(errorContextList).find(x => x === "displayMessage")) {
        notify(errorContextList?.displayMessage, 'negative')
      }
    }
  }, [isErrorContextList, errorContextList])
  useEffect(() => {
    if (isErrorUpdateContext && errorUpdateContext) {
      notify(errorUpdateContext?.displayMessage, 'negative')
    }
  }, [isErrorUpdateContext, errorUpdateContext])
  useEffect(() => {
    if (contextList && contextList?.constructor?.name === "Array") {

      if (contextList?.length === 1) {
        if (signInTarget) {
          updateContext(`users/updateContext/${contextList[0].accessEntryId}`, { count })
          return;
        }
        if (passwordExpired) {
          dispatch(push('/change-password'))
        } else {
          if (contextList[0]?.container) {
            dispatch(push('/fileTracker'))
            dispatch(navSelected('/fileTracker'))
          } else {
            dispatch(push('/'))
            dispatch(navSelected('/'))
          }
        }
      } else {
        if (signInTarget) {
          dispatch(push('/choose-context'))
          return;
        }
        const ctx = contextList?.filter(c => c.accessEntryId === accessEntryId)[0]
        if (passwordExpired) {
          dispatch(push('/change-password'))
        } else {
          if (ctx.container && ctx.container.toString() === '1') {
            dispatch(push('/fileTracker'))
            dispatch(navSelected('/fileTracker'))
          } else {
            dispatch(push('/'))
            dispatch(navSelected('/'))
          }
        }
      }
      setcount(count + 1)
    }
  }, [contextList, signInTarget])


  useEffect(() => {
    if (updateContextResponse && signInTarget) {
      const ctx=contextList[0];

      dispatch(setContext(contextList[0]))
      dispatch(setSupportItems('singleContext'))
      const permissions = updateContextResponse
      dispatch(setPermissions(permissions));

      dispatch(updateServerConfig({
        clientLayout: `${ctx.client}${ctx.layout}`,
        userName: userId,
        dataSourceDefinition: ctx.dataSourceDefinition,
        dataSourceId: ctx.dataSourceId,
        token: sessionToken,
        roleId: ctx.roleId,
        userRole: ctx.roleName,
        userPermissions: permissions.map(perm => perm.name)
      }));
      if (passwordExpired) {
        dispatch(push('/change-password'))
      } else {
        if (contextList[0].container) {
          dispatch(push('/fileTracker'))
          dispatch(navSelected('/fileTracker'))
        } else {
          dispatch(push('/'))
          dispatch(navSelected('/'))
        }
      }
    }
  }, [updateContextResponse, signInTarget, contextList])
  useEffect(() => {
    try {
      if (auth?.user?.access_token && window.location.href?.split('?')[1]?.split("&")[1]?.split("=")[1]) {
        axiosInstanceObject.get(OICoreConfig.sentinel.userinfo, {
          headers: {
            'Authorization': `Bearer ${auth?.user?.access_token}`
          }
        })
          .then(function (resp) {
            setshowlogouttoast(false)
            setIsLoading(true);
            const userloginuniqueid = userid + Math.floor(Math.random() * Date.now())
            axios.post('/oauth', {
              userInfo: resp.data,
              token: auth?.user?.access_token
            }, {
              headers: {
                userloginuniqueid
              }
            }).then(res => {

              setsignInTarget(true)
              setIsLoading(false)
              setToken(res.data.token)
              dispatch(setLogonDetails({ ...res.data, userloginuniqueid }))
              getContexts({ count: count + 1 })


            }).catch(err => {
              if (err.error === 'PrevUserLoginFail') {
                signIn(data)
              }
              else {
                setIsLoading(false)
                setState({
                  key: state.key + 1,
                  type: 'negative',
                  text: `${err.displayMessage}`, //${err.ipAddress ? `IP ADDRESS: ${err.ipAddress}` : ''}
                })
              }
            });

          }).catch(function (err) {
            notify("We are not able to authenticate you at this time. We apologize for this inconvenience. Please try again later.", 'negative')
          });

      }


    }
    catch (e) {

    }
  }, [auth?.user?.access_token])
  
  // Show maintenance banner if its set
  useEffect(() => {
    axios.get('/getMaintenanceConfig')
      .then(response => {
        if (response.data && response.data?.response.length >= 1) {
          const data = response.data?.response[0];
          const maintenanceConfig = {
            id: data.ID,
            startDate: moment(data.T_START_DATE),
            endDate: moment(data.T_END_DATE),
            startTime: moment(data.T_START_DATE),
            endTime: moment(data.T_END_DATE),
            isIntermittently: data.B_INTERMITTENTLY,
          };

          const startDate = maintenanceConfig.startDate;
          const startTime = maintenanceConfig.startTime;
          const endDate = maintenanceConfig.endDate;
          const endTime = maintenanceConfig.endTime;
          const isIntermittently = maintenanceConfig.isIntermittently;
          const today = new Date();
          const todayCT = moment(today.toLocaleString('en-US', { timeZone: 'America/Chicago' }));

          if (data.T_START_DATE && data.T_END_DATE && endDate.isAfter(todayCT)) {
            setIsShowBanner(true);
            setMaintenanceMessage(`CAS will be ${Boolean(isIntermittently) ? 'intermittently' : ''} down for system maintenance from ${startDate.format('MMM DD')}, ${startTime.format('hh A')} - ${endDate.format('MMM DD')}, ${endTime.format('hh A')} CT`);
          }

        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const sentinelSignin = async () => {
    
    if (is_Identity_URL_Up) {
      let kc_idp_hint_value = isInternalUser ? "uhg-ms-id" : "optum-id";
      await signinRedirect({
        redirect_uri: OICoreConfig.sentinel.redirect_uri,
        kc_idp_hint: kc_idp_hint_value
      });
    }
    else {
      notify('We are currently unable to authenticate you.  Please try again later. We apologize for the inconvenience.', 'negative')
    }


  }

  const checkHostName = () => {
    if (window.location.hostname === "mcdsdev.emdeon.net") {
      window.location.replace("https://dl-memabfws004.development.webmd.net")
    } else if (window.location.hostname === "mcds.emdeon.net") {
      window.location.replace("https://ql-memabfws004.development.webmd.net")
    } else if (window.location.hostname === "mcdscert.emdeon.com") {
      window.location.replace("https://cascert.changehealthcare.com")
    } else if (window.location.hostname === "cas.emdeon.com") {
      window.location.replace("https://cas.changehealthcare.com")
    }
  }

  const showNotificationforMFA = (UrlbannerText) => {
    // notify('sdcf', 'caution')
    setState({
      key: state.key + 1,
      type: 'caution',
      text: UrlbannerText,
    })
  }
  const userNameChange = (e) => {
    setUserid(e.target.value);
  }
  const passwordChange = (e) => {
    setPassword(e.target.value);
  }

  const signIn = (data) => {
    setshowlogouttoast(false)
    setIsLoading(true)
    const userloginuniqueid = userid + Math.floor(Math.random() * Date.now())
    axios.post('/auth', {
      userId: userid,
      password: password,
      prodLoginPage: prodLoginPage,
      isMFALoggedInEnabled: isMFALoggedInEnabled
    }, {
      headers: {
        userloginuniqueid
      }
    }).then(res => {


      if (res?.data?.isMFAEnabled) {
        console.log("You are registered with One Healthcare ID. Please click the Secure Login button to continue.");
        setIsUSerEnforcedforSentinelLogin(true);
        notify("You are registered with One Healthcare ID. Please click the Secure Login button to continue.", 'caution');
      }
      else if (res?.data?.isUSerEnforcedforSentinelLogin) {
        console.log("Please register with One Healthcare ID to authenticate your user account. Click the Secure Login button to continue.");
        notify("Please register with One Healthcare ID to authenticate your user account. Click the Secure Login button to continue.", 'caution');
        setIsUSerEnforcedforSentinelLogin(true);
      }
      else {
        setToken(res.data.token)
        dispatch(setLogonDetails({ ...res.data, userloginuniqueid }))
        getContexts({ count: count + 1 });
        setsignInTarget(true);
      }
      setIsLoading(false);


    }).catch(err => {
      if (err.error === 'PrevUserLoginFail') {
        signIn(data)
      }
      else {
        setIsLoading(false)
        setState({
          key: state.key + 1,
          type: 'negative',
          text: `${err.displayMessage}`, //${err.ipAddress ? `IP ADDRESS: ${err.ipAddress}` : ''}
        })
      }
    })
  }

  const handleForgotPassword = () => dispatch(push('/forgot'));

  const oldSignIn = (data) => {
    setshowlogouttoast(false)
    setIsLoading(true)
    const userloginuniqueid = data.userid + Math.floor(Math.random() * Date.now())
    axios.post('/auth', {
      userId: data.userid,
      password: data.password,
      prodLoginPage: prodLoginPage,
      isMFALoggedInEnabled: isMFALoggedInEnabled
    }, {
      headers: {
        userloginuniqueid
      }
    }).then(res => {


      if (res?.data?.isMFAEnabled) {
        console.log("You are registered with One Healthcare ID. Please click the Secure Login button to continue.");
        setIsUSerEnforcedforSentinelLogin(true);
        notify("You are registered with One Healthcare ID. Please click the Secure Login button to continue.", 'caution');
      }
      else if (res?.data?.isUSerEnforcedforSentinelLogin) {
        console.log("Please register with One Healthcare ID to authenticate your user account. Click the Secure Login button to continue.");
        notify("Please register with One Healthcare ID to authenticate your user account. Click the Secure Login button to continue.", 'caution');
        setIsUSerEnforcedforSentinelLogin(true);
      }
      else {
        setToken(res.data.token)
        dispatch(setLogonDetails({ ...res.data, userloginuniqueid }))
        getContexts({ count: count + 1 });
        setsignInTarget(true);
      }
      setIsLoading(false);

    }).catch(err => {
      if (err.error === 'PrevUserLoginFail') {
        signIn(data)
      }
      else {
        setIsLoading(false)
        setState({
          key: state.key + 1,
          type: 'negative',
          text: `${err.displayMessage}`, //${err.ipAddress ? `IP ADDRESS: ${err.ipAddress}` : ''}
        })
      }
    })
  }



  const checkRedirectLocation = useMemo(() => {
    if (token && sessionToken && !signInTarget) {
      if (accessEntryId) {

        getContexts({ count })
        return <></>
      } else {
        if (contextList?.length > 1) {
          return <Redirect to="/choose-context" />
        } else {
          return <></>
        }
      }
    } else {
      return (
        <>
          <Masthead
            currentProductName="Client Access System"
          />
          <NotificationBanner
            key={state.key}
            dataTestId="test-NotificationBanner"
            domID="NotificationBanner"
            text={state.text}
            type={state.type}
          />

          {isShowBanner && (<NotificationBanner
            domID={'maintenanceMessage'}
            autoDismiss={false}
            text={maintenanceMessage}
            type={"caution"}
            icon={Warning}
          />)}

          {isLoading || isLoadingContexts || isLoadingUpdateContext ?
            (<div style={{ position: 'relative' }}>
              <div className="loadingLoginBtn">
                <LoadingIndicator />
              </div>
            </div>) : ''}
          <>
            {
              !isMFALoggedInEnabled &&
              <LoginForm
                dataTestId="test-LoginCard"
                domID="LoginCard"
                onForgotPassword={handleForgotPassword}
                onValidSubmit={oldSignIn}
                description
                passwordErrorMessage="Enter your password"
                title="Welcome"
                usernameErrorMessage="Enter your email"
                usernameLabel="User ID"
                hideRememberMe
                buttonProps={{ disabled: isLoading || isLoadingContexts, type: 'submit' }}
              />

            }
          </>


          <>{
            isMFALoggedInEnabled &&
            <div className="loginCardPage">

              <div className='dkIQRq'>
                <div color="primary" className="sc-hjGZqJ ehsEcd title" style={{ textAlign: 'center' }}>

                  <div style={{ color: "#0F0F59", fontWeight: "900" }}>
                    Welcome
                  </div>
                  <>
                    {
                      isMFALoggedInEnabled &&
                      <>

                        <div >
                          <p style={{
                            "color": "#4CC6FF", fontSize: "13px", lineHeight: "100%",
                            fontWeight: 'normal'
                          }}>
                            <b>By the end of December 2023</b>, you will be required to have Multi-Factor Authentication to sign in to CAS.
                          </p>

                          <p style={{
                            fontSize: "13px", lineHeight: "100%",
                            fontWeight: '500'
                          }}>
                            Use your <b>CAS email</b> to register for Multi-Factor Authentication or sign in if already registered.
                          </p>
                        </div>
                        <Button
                          buttonType="standard"
                          dataTestId="test-defaultButton"
                          domID="automation-id"
                          name="Secure Login"
                          className="secureLogin"
                          size="medium"
                          type="button"
                          onClick={sentinelSignin}
                        />
                        {
                          !isUSerEnforcedforSentinelLogin && <h2>
                            <span>Or</span>
                          </h2>
                        }

                      </>
                    }

                  </>

                  {
                    !isUSerEnforcedforSentinelLogin &&
                    <Accordion dataTestId="test-accordion" defaultExpandedIndex={0}>
                      <CollapsePanel domID="accordion-section-1">
                        <CollapsePanelTitle domID="accordian-section-1-title">
                          Sign in with your CAS credentials
                        </CollapsePanelTitle>
                        <CollapsePanelDetails>
                          <div className="form">

                            <div className="input-container">
                              <label className='loginlabel'>Username </label>
                              <input type="text" name="uname" onChange={(e) => { userNameChange(e) }} value={userid} />
                            </div>
                            <div className="input-container">
                              <label className='loginlabel'>Password </label>
                              <input type="password" name="pass" onChange={(e) => { passwordChange(e) }} value={password} />

                            </div>
                            <div >
                              <Button
                                buttonType="standard"
                                dataTestId="test-defaultButton"
                                domID="automation-id"
                                name="Login"
                                className="secureLogin"
                                size="medium"
                                type="button"
                                onClick={signIn}
                              />
                            </div>

                          </div>

                          <div>
                          {
                              !isMFALoggedInEnabled &&
                              <button className='forgotpwdButton' onClick={handleForgotPassword} >forgot your password</button>
                            }
                          </div>
                        </CollapsePanelDetails>
                      </CollapsePanel>

                    </Accordion>

                  }




                </div>
              </div>
            </div>
          }
          </>


          <LoggedOutFooter />
          {showlogouttoast ? <ToastContainer hideProgressBar={true} autoClose={false} /> : null}
        </>
      )
    }
  }, [userid, password, token, sessionToken, signInTarget, accessEntryId, count, passwordExpired,
    state, isLoading, isLoadingContexts, isLoadingUpdateContext, showlogouttoast, isUSerEnforcedforSentinelLogin, isShowBanner, maintenanceMessage]);



  return (
    <>
      {checkRedirectLocation}
    </>
  )
}

export default Internalogin;